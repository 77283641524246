import React from "react";
import { Col, Row, Nav } from "react-bootstrap";
import Layout from "./layout";
import SEO from "./seo";
import { Link } from "gatsby";
import { HELPCENTER_FAQ_URL } from "../util/constants";

const HardwareBase = ({ children, title, platformName }) => {
  const seoTitle = `Taplist.io setup instructions for ${platformName}`;
  const seoDescription = `Instructions for setting up a digital beverage menu on ${platformName}.`;
  const inPageTitle = `${platformName} Setup Guide`;
  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <section className="section" style={{ paddingTop: 30 }}>
        <Row>
          <Col md={3}>
            <Link to="/hardware">&laquo; Hardware Support</Link>
          </Col>
          <Col>
            <h1>{inPageTitle}</h1>
            {children}
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default HardwareBase;
