import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import HardwareBase from "../../components/hardware-base";
import { DASHBOARD_URL, BRAND_NAME } from "../../util/constants";

const AppleTVSetup = () => {
  const q = useStaticQuery(graphql`
    query {
      fireTv: file(relativePath: { eq: "help/firetv.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      installScreen: file(
        relativePath: { eq: "help/firetv-install-screen.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      setupPairing: file(relativePath: { eq: "help/setup-pairing.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      setupComplete: file(relativePath: { eq: "help/setup-complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <HardwareBase platformName="Apple TV">
      <Row>
        <Col>
          <p className="lead">
            It's extremely easy to turn an{" "}
            <a
              href="https://www.apple.com/shop/buy-tv/apple-tv-4k"
              target="_new"
            >
              Apple TV Device
            </a>{" "}
            into a digital beer board or beverage menu with Taplist.io. Follow these steps.
          </p>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Step 1: Get a Device</h3>
          <p>
            You'll need an Apple TV device that can download and run apps.
            Taplist.io works with the following Apple TV devices:
          </p>
          <ul>
            <li>
              <b>Apple TV 4K (2nd generation)</b>: Released in 2021
            </li>
            <li>
              <b>Apple TV 4K (1st generation)</b>: Released in 2017
            </li>
            <li>
              <b>Apple TV HD</b>: Released in 2015
            </li>
          </ul>
          <p>
            Once you have one of these devices, proceed to the next step.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.fireTv.childImageSharp.fluid}
            alt="An Amazon FireTV Stick"
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Step 2: Install the App</h3>
          <p>
            Open the <b>App Store</b> on the device and search for{' '}
            <tt>taplist.io</tt>. You should see our app. Install it!
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.installScreen.childImageSharp.fluid}
            alt={`${BRAND_NAME} in the Amazon App Store`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Step 3: Pair Your Display</h3>
          <p>
            Open the Taplist.io App on your device. The first time it launches,
            you should see a pairing code.
          </p>
          <p>
            Using your mobile phone, laptop, or any other device, visit the{" "}
            activation page and enter the code shown on screen.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.setupPairing.childImageSharp.fluid}
            alt={`The ${BRAND_NAME} pairing screen`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Done!</h3>
          <p>
            You don't have to to anything else once your device has been
            successfully paired! The screen will automatically update after
            a few seconds.
          </p>
          <p>
            The Apple TV app is designed to be "set it and forget": You
            completely manage and control all aspects of your new display
            through the{"  "}
            <a href={DASHBOARD_URL}>Taplist.io Dashboard</a>, not the device
            itself.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.setupComplete.childImageSharp.fluid}
            alt={`The ${BRAND_NAME} menu display`}
          />
        </Col>
      </Row>
    </HardwareBase>
  );
};

export default AppleTVSetup;
